import {FILTER_BY_CATEGORY, FILTER_BY_SERVICES, FILTER_BY_SECTORS, FILTER_BY_DEPARTMENTS, FILTER_BY_LEVELS, SEARCH_LIST_DEPARTMENT} from '../actions/actionsTypes'

export const CommonReducer = (state, action) => {
    switch (action.type) {

      case FILTER_BY_CATEGORY:
        return { 
            ...state,
            filter_by_cat: action.filter_by_cat
        };

      case FILTER_BY_SERVICES:
        return { 
            ...state,
            filter_by_serv: action.filter_by_serv
        };
      
      case FILTER_BY_SECTORS:
        return { 
            ...state,
            filter_by_sec: action.filter_by_sec
        };
      
      case FILTER_BY_DEPARTMENTS:
        return { 
            ...state,
            filter_by_depart: action.filter_by_depart
        };

      case FILTER_BY_LEVELS:
        return { 
            ...state,
            filter_by_level: action.filter_by_level
        };

      case SEARCH_LIST_DEPARTMENT:
        return { 
            ...state,
            search_list_department: action.search_list_department
        };
          
      default:
        throw new Error();
    }
};